<template>
  <div class="orderList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>订单列表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="search_box">
      <span>订单状态：</span>
      <el-select :clearable="true" style="width:200px;" v-model.trim="formData.status">
        <el-option :key="-1" label="所有" :value="undefined"></el-option>
        <el-option :key="1" label="待付款" :value="1"></el-option>
        <el-option :key="2" label="待发货" :value="2"></el-option>
        <el-option :key="3" label="待收货" :value="3"></el-option>
        <el-option :key="4" label="待评价" :value="4"></el-option>
        <el-option :key="9" label="交易完成" :value="9"></el-option>
        <el-option :key="10" label="交易取消" :value="10"></el-option>
      </el-select>
      <div style="margin:0 20px">
        <span>订单来源：</span>
      <el-select :clearable="true" style="width:200px;" v-model.trim="formData.orderSource">
        <el-option :key="-2" label="全部" value="-2"></el-option>
        <el-option :key="0" label="小程序" value="1"></el-option>
        <el-option :key="1" label="H5" value="2"></el-option>
        <el-option :key="2" label="PC" value="3"></el-option>
        <el-option :key="3" label="Android" value="4"></el-option>
        <el-option :key="4" label="IOS" value="9"></el-option>
      </el-select>
      </div>
      <span>店铺：</span>
      <el-select :clearable="true" style="width:200px;" v-model.trim="formData.storeId">
        <el-option :key="-1" label="所有" :value="undefined"></el-option>
      </el-select>
      <span>收货方式：</span>
      <el-select style="width:200px;" :clearable="true" v-model.trim="formData.receiveWay">
        <el-option :key="-1" label="所有" :value="undefined"></el-option>
        <el-option :key="0" label="快递" :value="0"></el-option>
        <el-option :key="1" label="自提" :value="1"></el-option>
      </el-select>
      <div style="margin:10px 0">
        <span style="margin-right:12px;margin-left:20px">订单号：</span>
      <el-input v-model="formData.coding" placeholder="请输入订单号" style="width:200px;" :clearable="true"></el-input>

      
      </div>
      <div style="margin:10px 0">
        <span style="margin-right:12px">收货人：</span>
      <el-input v-model="formData.receiveName" placeholder="请输入收货人名称" style="width:200px;" :clearable="true"></el-input>

       <span>活动Id: </span>
        <el-input v-model="formData.activityId" placeholder="请输入活动Id" type="number" style="width:200px;" :clearable="true"></el-input>

        <span>活动区域Id: </span>
        <el-input v-model="formData.areaId" placeholder="请输入活动区域Id" type="number" style="width:200px;" :clearable="true"></el-input>

      <el-button type="primary" class="btn" @click="getsblist()">查询</el-button>
      <el-button type="primary" class="btn" @click="inPortTabel()">导出订单</el-button>
      </div>
     
    </div>

    <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="margin-top:40px; width:98%;">
      <el-table-column label="商品" type="expand">
        <template slot-scope="props">
          <el-table :data="props.row.storeUserOrderProdList" style="width:100%" border :default-sort="{prop:'date', order:'descending'}">
            <el-table-column prop="prod_id" label="商品ID" width="180"></el-table-column>
            <el-table-column prop="prod_name" label="商品名称"></el-table-column>
            <el-table-column prop="prod_home_img" label="商品封面">
              <template slot-scope="{row}">
                <el-image :src="row.prod_home_img" :preview-src-list="[row.prod_home_img]" style="width:60px;height:60px;"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="购买规格">
              <template slot-scope="{row}">
                {{row.prod_one_item_name||''}} {{row.prod_two_item_name||''}} {{row.prod_three_item_name||''}}
              </template>
            </el-table-column>
            <el-table-column prop="prod_buy_price" label="价格"></el-table-column>
            <el-table-column prop="prod_buy_integral" label="积分"></el-table-column>
            <el-table-column prop="prod_buy_count" label="数量"></el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="ID" prop="order_id" width="50"></el-table-column>
      <el-table-column label="订单号" prop="order_coding" width="130"></el-table-column>
      <el-table-column label="订单来源" prop="strSource" width="80"></el-table-column>
      <el-table-column label="用户昵称" width="100px">
        <template slot-scope="{row}">
          {{row.userInfo.user_nickName}}
        </template>
      </el-table-column>
      <el-table-column label="用户头像" width="100">
        <template slot-scope="{row}">
          <el-image :src="row.userInfo.user_headImgUrl" :preview-src-list="[row.userInfo.user_headImgUrl]" style="width:60px; height:60px;"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="订单积分" prop="order_integral" width="80"></el-table-column>
      <el-table-column label="订单金额" prop="order_balance" width="80"></el-table-column>
      <el-table-column label="折扣金额" prop="order_coupon_price" width="80"></el-table-column>
      <el-table-column label="总金额" prop="order_total_price" width="80"></el-table-column>
      <el-table-column label="商品数量" prop="totalCount" width="80"></el-table-column>
      <el-table-column label="收货方式">
        <template slot-scope="{row}">{{row.order_receive_way==0?'快递' :'自提'}}</template>
      </el-table-column>
      <el-table-column label="支付方式" prop="strPayWay">
        
      </el-table-column>
      <el-table-column label="订单状态">
        <template slot-scope="{row}">
          {{
            row.order_status==1?'待付款' :
            row.order_status==2?'待发货' :
            row.order_status==3&&row.order_receive_way==0?'待收货' :
            row.order_status==3&&row.order_receive_way==1?'待提货' :
            row.order_status==4?'待评价' :
            row.order_status==5?'申请退款' :
            row.order_status==6?'同意退款待用户退货' :
            row.order_status==7?'用户已退货待商家确认' :
            row.order_status==8?'退货成功' :
            row.order_status==9?'已完成' :
            row.order_status==10?'交易取消' :'未知状态'
           }}
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="activityTitle"
          label="	活动标题"
          width="width"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="areaName"
          label="	区域名称"
          width="width"
        ></el-table-column>
      <el-table-column label="操作" width="280px">
        <template slot-scope="{row}">
          <el-button size="mini" type="primary" @click="toShowSend(row)" v-if="row.order_status==2">发货</el-button>
          <el-button size="mini" type="primary" @click="$router.push('/selectMall/order/detail?id=' + row.order_id)">查看详情</el-button>
          <el-button size="mini" type="primary" @click="onShowExpress(row.order_id)">物流详情</el-button>
          
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="formData.currentPage"
      :page-sizes="[5, 10, 15, 20]" :page-size="formData.pageSize" layout="total, sizes, prev, pager, next, jumper"
      :total="formData.total">
    </el-pagination>

    <!-- 发货弹窗 -->
    <el-dialog :close-on-click-modal="false" title="发货" :visible.sync="sendVisible" width="width">
      <el-form :rules="sendRules" :model="sendForm" ref="sendRuleForm" label-width="80px">
        <el-form-item label="选择物流" prop="expressId">
          <el-select style="width:300px;" v-model.trim="sendForm.expressId" @change="changee">
            <el-option v-for="(i, index) in express" :key="i.expressId" :value="i.expressId" :label="i.expressName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物流单号" prop="expressCoding">
          <el-input v-model="sendForm.expressCoding" placeholder="请输入物流编号" style="width:300px;"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="sendVisible = false">取 消</el-button>
        <el-button type="primary" @click="formv('sendRuleForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 物流信息 -->
    <el-dialog
    v-show="isShowExpress"
    title="提示"
    :visible.sync="dialogVisible"
    width="40%"
    >
     <template>
        <li class="checkYep">{{expressCheck}}</li>
      </template>
    <div v-for="item in expressDetail" :key="item.action">
    <ul>
      <li class="exp">{{item.AcceptStation}}</li>
    </ul>
    </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
  import {
    storeOrderList,
    expressAll,
    orderToSend,
    inPort,
    getExpressDetail,
    
  } from '../../../api/selectMall.js'
  export default {
    data() {
      return {
        expressCheck:'',
        expressDetail:'',
        dialogVisible: false,
        isShowExpress:false,
        tableData: [],
        formData: {
          storeId: '',
          status: undefined,
          receiveWay: undefined,
          receiveName: '',
          total: 0,
          pageSize: 10,
          currentPage: 1,
          orderSource:'-2',
          coding:'',
          activityId:0,
          areaId:0
        },

        express: [],
        sendVisible: false,
        sendForm: {
          orderId: '',
          expressId: '',
          expressName: '',
          expressCoding: '',
        },
        sendRules: {
          expressId: [{
            required: true,
            message: '请选择发货物流',
            trigger: 'change'
          }],
          expressCoding: [{
            required: true,
            message: '请输入物流编号',
            trigger: 'blur'
          }],
        },
      }
    },
    created() {
      this.getsblist()
      this.gete()
    },
    methods: {
   
         async inPortTabel(){
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
         const {data} = await inPort({currentPage:1})
          
      
      let url = window.URL.createObjectURL( data)//转换文件流为URL
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", `精选商城订单表.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      loading.close();
       
        // }else{
           
        //    this.$message.warning(data.msg)
        // }
      },
      async onShowExpress(row){
        this.dialogVisible = true
        this.isShowExpress = true
        const {data} = await getExpressDetail({'orderId':row})
        this.expressCheck = data.data.expResult.Traces[data.data.expResult.Traces.length-1]
        this.expressCheck = this.expressCheck.AcceptStation
			  this.expressDetail = data.data.expResult.Traces
			  this.expressDetail.reverse().shift()
      },
      async getsblist() {
        this.formData.activityId =this.formData.activityId || 0;
        this.formData.areaId =this.formData.areaId || 0;
        const {
          data
        } = await storeOrderList(this.formData);
        if (data.code == 0) {
          console.log(data,'data')
          data.list.forEach(item => {
            console.log(item,'item')
            item.totalCount = 0
            item.storeUserOrderProdList.forEach(subItem => {
              item.totalCount += subItem.prod_buy_count
            })
          })
        }
        this.tableData = data.list;
        console.log(this.tableData,'tableData')
        this.formData.total = data.pagination.total
        this.formData.pageSize = data.pagination.pageSize;
        this.formData.currentPage = data.pagination.current;
      },
      async gete() {
        const {
          data
        } = await expressAll({
          orderId: this.$route.query.id
        })
        if (data.code == 0) {
          this.express = data.list
        }
      },
      toShowSend(row) {
        this.sendForm.orderId = row.order_id
        if (row.order_receive_way == 1) {
          this.sendForm.order_receive_way = 1
          this.$confirm('该订单为自提订单, 确认已将商品送往自提点？', '自提订单', {
              distinguishCancelAndClose: true,
              confirmButtonText: '确定',
              cancelButtonText: '取消'
            })
            .then(() => {
              this.csed()
            })
            .catch(action => {
              console.log(action)
            });
          return
        }
        this.sendVisible = true
        this.gete()
      },
      changee(val) {
        console.log(val)
        this.express.forEach(item => {
          if (item.expressId == val) {
            this.sendForm.expressName = item.expressName
          }
        })
      },
      formv(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid || this.sendForm.order_receive_way == 1) {
            this.csed()
          } else {
            this.$message({
              type: "error",
              message: "请把数据填写完整",
            });
            return false;
          }
        });
      },
      async csed() {
        const {
          data
        } = await orderToSend({
          orderId: this.sendForm.orderId,
          expressId: this.sendForm.expressId,
          expressName: this.sendForm.expressName,
          expressCoding: this.sendForm.expressCoding,
        })
        if (data.code == 0) {
          this.$message.success('发货成功!')
          this.sendVisible = false
          this.getsblist()
        } else {
          this.$message.error(data.msg)
        }
      },
      handleSelectionChange(arr) {
        this.multipleSelection = arr;
      },
      handleSizeChange(num) {
        this.formData.pageSize = num;
        this.getsblist()
      },
      handleCurrentChange(num) {
        this.formData.currentPage = num;
        this.getsblist()
      },
    }
  }
</script>
<style lang="less" scoped="scoped">
  .orderList {
    .el-breadcrumb {
      height: 35px;
      border-bottom: 1px solid #c0bfbf
    }

    .add {
      margin-top: 20px
    }

    .el-table {}

    .search_box {
      margin-top: 24px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      span {
        margin-left: 20px;
        font-size: 15px;
        color: #333333;

        &:first-child {
          margin-left: 0
        }
      }

      .btn {
        margin-left: 20px
      }
    }

    .el-pagination {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end
    }
  }
   .exp{
     font-size: 18px;
    // font-weight:bold ;
    line-height: 50px;
  }
    .checkYep{
      line-height: 50px;
    font-size: 18px;
    color:#FFA544;
    line-height: px;
  }
</style>
